.nav-root{
    background-color: black;
    color: white;
    width:100%;
}

.nav-content{
    background-color: black;
    color: white;
    width:50%;
    min-width: 25cm;
    max-width: 30cm;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 2rem;
    padding-right: 2rem;
}

.nav-content ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;

}

.nav-content a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.nav-content li:hover{
    background-color: rgb(95, 95, 95);
}

.nav-content li.active{
    background-color: rgb(199, 199, 199);
    color:black;
    font-weight: bold;
}