.footer-root{
    display:flex;
    background-color: black;
    color:white;
    width:100%;
    height:18vh;
    max-height:5cm;
}

.footer-content{
    display:flex;
    width:50%;
    min-width: 25cm;
    max-width: 30cm;
    height:18vh;
    max-height:5cm;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer-content a {
    color: white;
    text-decoration: none; 
}

.copyright-statement{
    font-size: 1rem;
    color: rgb(150, 150, 150);
}