.body-root{
    background-color: white;
    color:black;
    width:100%;
}

.body-content{
    display:flex;
    flex-wrap: wrap;
    height:100%;
    width:50%;
    min-width: 25cm;
    max-width: 30cm;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #af1f1f;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
 }
  
.button:hover {background-color: #490a08}
  
.button:active {
    background-color: #d45555;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
 }

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
 }

.banner{
    margin-bottom: 1rem;
}

.winners-one-panel{
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 1rem;
    text-align: center;
}

.winners-one-panel img{
    width: 50%;
}

.winners-two-panel{
    width: 48%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 1rem;
    text-align: center;
}

.two-panel{
    width: 48%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 1rem;
}

.people-two-panel-left{
    width: 18%;
    padding: 1%;
    margin-bottom: 2rem;
}

.people-two-panel-right{
    width: 78%;
    min-height: 23rem;
    margin-bottom: 2rem;
}

.people-name{
    margin-top: 0;
    margin-bottom: 0;
}

.people-title{
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-size: 0.9rem;
}

.three-panel{
    width: 32%;
    padding-left: 0.5%;
    padding-right: 0.5%;
}

.side-panel{
    width: 20%;
    padding-top: 1rem;
}

.content-with-side-panel{
    display: flex;
    flex-wrap: wrap;
    width: 78%;
    height: 100%;
    padding: 1%;
}

.content-with-side-panel hr{
    width:100%;
    margin-bottom: 2rem;
}

.people-img{
    height: auto; 
    width: 100%;
}

.body-link{
    color:black;
    text-decoration: underline;
}