.sidenav-root{
    background-color: white;
    color: black;
    width:auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.sidenav-content{
    color: black;
    width:100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.sidenav-content ul{
    background-color:  rgb(240, 240, 240);
    padding: 0;
    margin:0;
    margin-top: 0.1rem;
    list-style: none;
    display: flex;
}

.sidenav-content a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.sidenav-content ul:hover{
    background-color: rgb(230, 230, 230);
    color:white;
}

.sidenav-content ul.active{
    border-style: solid;
    border-color: white;
    border-left-color: rgb(150, 0, 0);
    border-left-width: 0.2rem;
    background-color: white;
    color:black;
    font-weight: bold;
}